import React from "react";
import PropTypes from "prop-types";

import locationIcon from "images/icons/location.svg";
import childrenIcon from "images/icons/children.svg";
import dateIcon from "images/icons/date-shaded.svg";
import qualitiesIcon from "images/icons/qualities-shaded.svg";

import IconRow from "./icon-row";

const FamilyProperties = ({
  location,
  startDate,
  children,
  qualities,
  highlight,
}) => {
  return (
    <div className="mv2 medium-gray">
      <IconRow
        label="Location"
        icon={locationIcon}
        isHighlit={highlight === "distance"}
      >
        {location}
      </IconRow>
      <IconRow
        label="Ideal start"
        icon={dateIcon}
        isHighlit={highlight === "startDate"}
      >
        {startDate}
      </IconRow>
      <IconRow label="Children" icon={childrenIcon}>
        {children}
      </IconRow>
      <IconRow label="Qualities" icon={qualitiesIcon}>
        {qualities}
      </IconRow>
    </div>
  );
};

FamilyProperties.propTypes = {
  children: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  qualities: PropTypes.string.isRequired,
  highlight: PropTypes.oneOf(["startDate", "distance"]),
};

export default FamilyProperties;
