import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import normalizeOptions from "utils/normalize-options.js";
import useUniqueId from "hooks/use-unique-id";

import css from "./index.module.css";

const Select = ({
  options,
  id,
  field = {},
  label,
  className,
  error,
  selectClassName,
  placeholder,
  name = field.name,
  value = field.value,
  onChange = field.onChange,
  ...rest
}) => {
  const inputId = useUniqueId(id);

  const rootClasses = cx(error && css.error, className);

  const selectClasses = cx(
    !value && css.noSelection,
    selectClassName,
    "input-reset input bg-white br0 ba b--black-20 pv2 pl3 pr4 db f6 br2 b"
  );

  const { form: _, ...selectRest } = rest;

  return (
    <div className={rootClasses}>
      {label && (
        <label className="pv1 mb1 purple f6 mb1 dib lh-title" htmlFor={inputId}>
          {label}
        </label>
      )}
      <div className={css.wrapper}>
        <select
          id={inputId}
          className={selectClasses}
          name={name}
          onChange={onChange}
          value={value}
          {...selectRest}
        >
          {placeholder && <option value="">{placeholder}</option>}
          {normalizeOptions(options).map(([text, val]) => (
            <option key={val} value={val}>
              {text}
            </option>
          ))}
        </select>
      </div>
      {error && error !== true && (
        <div className="f6 mt1 pt1 orange">{error}</div>
      )}
    </div>
  );
};

Select.propTypes = {
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  field: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  selectClassName: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default Select;
