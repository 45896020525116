import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import useUniqueId from "hooks/use-unique-id";

import css from "./index.module.css";

const Checkbox = ({
  className,
  labelClassName,
  id,
  field = {},
  name = field.name,
  value = field.value,
  onChange = field.onChange,
  label,
  style,
  ...rest
}) => {
  const inputId = useUniqueId(id);
  const { form: _, ...inputRest } = rest;
  const rootClasses = cx(css[style], className, "cf");

  return (
    <div className={rootClasses}>
      <input
        type="checkbox"
        id={inputId}
        name={name}
        checked={value}
        onChange={onChange}
        {...inputRest}
      />
      <label htmlFor={inputId} className={labelClassName}>
        {label}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  style: PropTypes.oneOf(["small", "large"]).isRequired,
  value: PropTypes.bool,
  className: PropTypes.string,
  field: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

export default Checkbox;
