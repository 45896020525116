import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { startsWith, isEmpty, filter, set, lensProp } from "ramda";

import { analyticsEvent } from "utils/analytics";
import { pricePerMonth } from "utils/pricing";
import useScrollOnError from "hooks/scroll-on-error";

import ActionFlowContainer from "components/action-flow-container";
import Checkbox from "components/checkbox";
import InfoSheet from "components/info-sheet";
import Button from "components/button";
import FormErrors from "components/form-errors";
import Select from "components/select";

import moneyIcon from "images/icons/money.svg";
import daysIcon from "images/icons/days.svg";

import DayRow from "../day-row";

import css from "./index.module.css";

const MINMAX_DAY_OPTIONS = [
  ["1 day", 1],
  ["2 days", 2],
  ["3 days", 3],
  ["4 days", 4],
  ["5 days", 5],
  ["6 days", 6],
  ["7 days", 7],
];

const upcaseFirst = (input) => input.charAt(0).toUpperCase() + input.substr(1);

const Form = ({
  backTo,
  submitLabel,
  isSubmitting,
  handleSubmit: onSubmit,
  errors,
  values: { days, unsureNumDaysCare, unsureDays, minDaysCare, maxDaysCare },
  setFieldValue,
}) => {
  const [isBreakdownOpen, setBreakdownOpen] = useState(false);
  const [isUnsureNumDaysCareOpen, setUnsureNumDaysCareOpen] = useState(false);
  const [isUnsureDaysOpen, setUnsureDaysOpen] = useState(false);
  const isMountedRef = useRef(false);

  useScrollOnError(errors, isSubmitting);

  useEffect(() => {
    if (unsureNumDaysCare && isMountedRef.current) {
      setUnsureNumDaysCareOpen(true);
      setFieldValue("minDaysCare", "");
      setFieldValue("maxDaysCare", "");
    }
  }, [unsureNumDaysCare, setFieldValue]);

  useEffect(() => {
    if (unsureDays && isMountedRef.current) {
      setUnsureDaysOpen(true);
      setFieldValue("days", {
        monday: "no",
        tuesday: "no",
        wednesday: "no",
        thursday: "no",
        friday: "no",
        saturday: "no",
        sunday: "no",
      });
    }
  }, [unsureDays, setFieldValue]);

  useEffect(() => {
    if (minDaysCare || maxDaysCare) {
      setFieldValue("unsureNumDaysCare", false);
    }
  }, [minDaysCare, maxDaysCare, setFieldValue]);

  useEffect(() => {
    if (!isEmpty(filter(startsWith("yes"), days))) {
      setFieldValue("unsureDays", false);
    }
  }, [days, setFieldValue]);

  useEffect(() => {
    isMountedRef.current = true;
  }, []);

  const handleRequestBreakdownOpen = () => {
    const label = `Approx. £${pricePerMonth(days)} / month`;
    analyticsEvent("Days Screen", "View price breakdown", label);

    setBreakdownOpen(true);
  };

  const handleRequestBreakdownClose = () => {
    setBreakdownOpen(false);
  };

  const handleRequestUnsureNumDaysCareClose = () => {
    setUnsureNumDaysCareOpen(false);
  };

  const handleRequestUnsureDaysClose = () => {
    setUnsureDaysOpen(false);
  };

  const effectiveMinDaysCare = unsureNumDaysCare ? 1 : minDaysCare;

  return (
    <>
      <ActionFlowContainer
        title="How many days per week do you need childcare?"
        description="If you need 3 days, select both 3 for maximum and minimum"
        backTo={backTo}
      >
        <div className="flex flex-column">
          <div className="flex-grow-1">
            <FormErrors className="mb3" errors={errors} />
            <div className={css.row}>
              <div className={`${css.cell} w-third-ns`}>
                <Field
                  name="minDaysCare"
                  label="Minimum"
                  component={Select}
                  selectClassName="w-100"
                  placeholder="-"
                  options={MINMAX_DAY_OPTIONS}
                  error={!!errors.minDaysCare}
                />
              </div>
              <div className={`${css.cell} w-third-ns`}>
                <Field
                  name="maxDaysCare"
                  label="Maximum"
                  component={Select}
                  selectClassName="w-100"
                  placeholder="-"
                  options={MINMAX_DAY_OPTIONS}
                  error={!!errors.maxDaysCare}
                />
              </div>
            </div>
            {(errors.maxDaysCare || errors.minDaysCare) && (
              <div className="f6 mt1 pt1 orange">
                {errors.minDaysCare || errors.maxDaysCare}
              </div>
            )}
            <Field
              name="unsureNumDaysCare"
              component={Checkbox}
              style="large"
              label="I don’t know yet"
              className="mt3"
              labelClassName="f3 lh-copy"
            />
            <h3 className="mt4 mb1 lh-copy">Which days do you need care?</h3>
            <div className="silver f6 mb3">
              We rule out families that don’t want the days you say are
              essential
            </div>
            {Object.keys(days).map((day) => (
              <DayRow
                key={day}
                name={day}
                value={days[day]}
                label={upcaseFirst(day.substr(0, 3))}
                onChange={(preference) =>
                  setFieldValue("days", set(lensProp(day), preference, days))
                }
              />
            ))}
            <Field
              name="unsureDays"
              component={Checkbox}
              style="large"
              label="I don’t know which days yet"
              labelClassName="f3 lh-copy"
            />
          </div>
          <div className="pb3 pt4 flex items-center">
            <div
              role="button"
              className="flex-grow-1 f6 pointer"
              onClick={handleRequestBreakdownOpen}
            >
              <div className="f6 rose lh-copy">View price breakdown</div>
            </div>
            <Button
              className="f6"
              disabled={isSubmitting}
              type="button"
              onClick={onSubmit}
            >
              {submitLabel}
            </Button>
          </div>
        </div>
      </ActionFlowContainer>
      <InfoSheet
        iconSrc={moneyIcon}
        isOpen={isBreakdownOpen}
        onRequestClose={handleRequestBreakdownClose}
        title="Nanny share pricing"
        message={
          <div className="f6">
            All day care
            <br />
            Approx. £{pricePerMonth(effectiveMinDaysCare)} / month
            <br />
            Sharing a nanny typically reduces your nanny costs by about 1/3 from
            sole care. For an all day baby & toddler nanny, sharing changes your
            cost from around £17.50 to £11.00 per hour all inclusive (depending
            on which nanny you decide to work with).
            <div className="mv2">
              Afterschool care
              <br />
              Sharing a nanny typically reduces your nanny costs by about 1/3
              from sole care. For after school care the approximate cost per
              hour for each parent with a shared nanny is £9 per hour all
              inclusive.
            </div>
            <a
              href="https://www.korukids.co.uk/content/baby-toddler/pricing"
              target="_blank"
              rel="noopener noreferrer"
              className="no-underline"
            >
              Learn more here
            </a>
          </div>
        }
      />
      <InfoSheet
        iconSrc={daysIcon}
        isOpen={isUnsureNumDaysCareOpen}
        onRequestClose={handleRequestUnsureNumDaysCareClose}
        title="Not sure how many days you need?"
        message={
          <>
            <p>
              As you’re not sure how many days you need, to increase your
              chances of finding a family, we’ll put you down as a minimum of 1
              and a maximum of 7. This way, you’ll have the best chance of
              finding a family.
            </p>

            <p className="mt2">
              If you get more clarity on the days you need at a later date, you
              can change your requirements at any time.
            </p>
          </>
        }
      />
      <InfoSheet
        iconSrc={daysIcon}
        isOpen={isUnsureDaysOpen}
        onRequestClose={handleRequestUnsureDaysClose}
        title="Not sure which days you need?"
        message={
          <>
            <p>
              As you’re not sure which days you need, to increase your chances
              of finding a family, we’ll put you down as Mon-Sun with everday
              marked as “Flexible”. This way, you’ll have the best chance of
              finding a family.
            </p>
            <p className="mt2">
              If you get more clarity on the days you need at a later date, you
              can change your requirements at any time.
            </p>
          </>
        }
      />
    </>
  );
};

Form.propTypes = {
  errors: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  backTo: PropTypes.object,
  submitLabel: PropTypes.string,
};

export default Form;
