import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import Checkbox from "components/checkbox";
import RadioButtonGroup from "components/radio-button-group";

import css from "./index.module.css";

const DayRow = ({ label, value, name, onChange }) => {
  const isSelected = value.substring(0, 3) === "yes";

  return (
    <div className={cx("flex items-center cf mb2 pb2")}>
      <Checkbox
        style="large"
        name={`${name}-preferable`}
        className="flex flex-grow-1"
        onChange={() => onChange(isSelected ? "no" : "yes_preferable")}
        checked={isSelected}
        label={label}
        labelClassName="f3 lh-copy w-100 dib relative"
      />
      <RadioButtonGroup
        value={value}
        name={`${name}-preference`}
        options={[
          ["Essential", "yes_essential"],
          ["Flexible", "yes_preferable"],
        ]}
        className={cx({ [css.hidden]: !isSelected })}
        buttonClassName={css.dayPreference}
        style="connected"
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

DayRow.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DayRow;
