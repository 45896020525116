import { differenceInCalendarDays, isSameYear, format } from "date-fns/fp";

export default (date) => {
  const now = new Date();

  const difference = differenceInCalendarDays(date, now);
  if (difference === 0) return "Today";
  if (difference === 1) return "Yesterday";
  if (difference > 1 && difference < 7) {
    return format("EEEE", date);
  }
  if (difference >= 7 && isSameYear(now, date)) {
    return format("E do MMM", date);
  }
  return format("do MMM yyyy", date);
};
