import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import css from "./index.module.css";

const { GOOGLE_STATIC_MAPS_KEY } = process.env;
const ZOOM_LEVEL = 15;

const StaticMap = ({ latitude, longitude }) => {
  const location = encodeURIComponent(`${latitude},${longitude}`);
  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${location}&style=feature:all|saturation:-100&zoom=${ZOOM_LEVEL}&size=600x300&scale=2&maptype=roadmap&key=${GOOGLE_STATIC_MAPS_KEY}`;
  const rootClasses = cx("db relative", css.root);

  return (
    <a
      href={`https://www.google.com/maps/@?api=1&map_action=map&center=${location}&zoom=${ZOOM_LEVEL}`}
      className={rootClasses}
    >
      <img src={mapUrl} alt="Map" className="absolute top-0" />
      <span className={css.opaqueCircle}></span>
    </a>
  );
};

StaticMap.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
};

export default StaticMap;
