import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import normalizeOptions from "utils/normalize-options";

import ToggleButton from "./toggle-button.js";

import css from "./index.module.css";

const ToggleButtonGroup = (props) => {
  const {
    className,
    buttonClassName,
    error,
    isChecked,
    label,
    labelClassName,
    name,
    onChange,
    options,
    style = "standard",
    type,
  } = props;

  const groupClasses = cx(css[style], error && css.error, className);
  const labelClasses = cx(css.label, "purple f6 mb1");
  const errorClasses = cx(css.label, "f6 mt1 pt1 orange");
  const buttonClasses = cx(css.button, buttonClassName);

  return (
    <>
      <div className={groupClasses}>
        {label && <div className={labelClasses}>{label}</div>}
        <div className={css.buttons}>
          {normalizeOptions(options).map(([optionLabel, optionValue]) => (
            <ToggleButton
              key={optionValue}
              type={type}
              label={optionLabel}
              value={optionValue}
              name={name}
              onChange={onChange}
              checked={isChecked(optionValue)}
              className={labelClassName}
              buttonClassName={buttonClasses}
            />
          ))}
        </div>
      </div>
      {error && <div className={errorClasses}>{error}</div>}
    </>
  );
};

ToggleButtonGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  type: PropTypes.oneOf(["checkbox", "radio"]).isRequired,
  isChecked: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  buttonClassName: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  style: PropTypes.oneOf(["standard", "connected"]),
};

export default ToggleButtonGroup;
