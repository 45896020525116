const analyticsEvent = (category, action, label) => {
  if (!window.ga) return;

  window.ga("gtag_UA_106989605_7.send", "event", {
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
  });
};

const fbEvent = (event) => {
  if (!window.fbq) return;

  window.fbq("track", event);
};

const setPage = (url) => {
  if (!window.ga) return;

  window.ga("gtag_UA_106989605_7.set", "page", url);
};

const trackPageView = (url) => {
  if (!window.ga) return;

  if (url) {
    window.ga("gtag_UA_106989605_7.send", "pageview", url);
  } else {
    window.ga("gtag_UA_106989605_7.send", "pageview");
  }
};

export { analyticsEvent, fbEvent, setPage, trackPageView };
