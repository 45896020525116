import React from "react";

export default [
  [
    "What is nanny share?",
    "Nanny share is where two families share one nanny, and the nanny looks after both families’ children at the same time (not one family on some days and the other family on other days). It’s a fab option for those looking for the flexibility and 1:1 care of a nanny, but who want to bring their costs down.",
  ],
  [
    "How much does it cost?",
    "Nanny shares cost each family around £100 for a full day of care (usually from 8am - 6pm). This is a total cost and includes all tax and national insurance. The cost can vary depending on the experience level of your nanny, whether the parents split the cost 50/50 and a few other factors.",
  ],
  [
    "Do you cover my area?",
    "The first part of setting up a nanny share is to find a family to share with. That's what this website is focused on. The website can be used by anyone, anywhere with a postcode. So, as long as you live in the UK, yes, we cover your area! In some places, like London, hundreds of other families are already signed up.",
  ],
  [
    "Nanny share vs nursery",
    <>
      <div className="pb2">
        There are pros and cons to both. In short, nanny share has the advantage
        of nursery in that you’re not tied to the opening days/hours of the
        nursery, your child can still get care if they are sick (nurseries won’t
        let sick children attend but will still charge), you don’t need to rush
        to get the child to somewhere on time (your nanny can take over a
        semi-dressed child as you run out the door), and you don’t need to be
        back at the nursery for a specific pick-up time (nannies are more
        flexible).
      </div>
      <div>
        You can{" "}
        <a
          href="https://intercom.help/korukids/en/articles/3425282-what-are-the-advantages-of-nanny-share-vs-nursery"
          target="_blank"
          rel="noopener noreferrer"
        >
          read more info about nanny share vs nurseries here
        </a>
      </div>
    </>,
  ],
  [
    "Nanny share vs childminding",
    <>
      <div className="pb2">
        Childminding is a good middle ground between nursery and nanny share,
        but the better ones are often full with long waiting lists. Unlike a
        nanny, you have to go to a childminder’s home, rather than them coming
        to your home, which can make a daily difference to your quality of life
        (you can run out the door with a semi-dressed child who’s just started
        breakfast rather then constantly cajoling them to speed up so you can
        leave on time). Childminder costs vary but are usually cheaper than
        either nursery or nanny. Childminders’ sickness policies vary, with some
        taking in sick children and others sending them home as a nursery would.
        The biggest challenge with childminders is that there aren’t nearly
        enough of them, and the good ones are heavily oversubscribed.
      </div>
      <div>
        You can{" "}
        <a
          href="https://intercom.help/korukids/en/articles/3425285-what-are-the-advantages-of-nanny-share-vs-childminder"
          target="_blank"
          rel="noopener noreferrer"
        >
          read more info about nanny share vs childminders here
        </a>
      </div>
    </>,
  ],
  [
    "Why find the family first and not the nanny?",
    "It may seem counterintuitive that we focus on finding a family rather than a nanny. After all, finding the nanny is the hard bit, right? Actually, no. We’ve learned from years of arranging nanny shares that the tricky bit is matching families. Find each other first, check that you and your children get on, and agree the qualities you’d both like to find in a nanny as well as logistics. Once you’ve done this, finding the nanny usually takes about a month (potentially longer if you have particularly unusual or specific requirements).",
  ],
  [
    "When/how do we find the nanny?",
    <>
      Once you’ve decided to go ahead with a family, it’s time to find the
      nanny. You can do this yourself for free, or if you want help (which most
      families do) we’d be delighted to help. Just email us at{" "}
      <a href="mailto:care@korukids.co.uk">care@korukids.co.uk</a> and we’ll
      start the hunt. As well as finding you a nanny, we also sort out Ofsted
      registration, DBS, first aid training, contract, payroll, pension, and a
      dozen other things we can tell you about when you’re ready. Finding a
      nanny can take anywhere between a few weeks and a few months depending on
      your exact requirements.
    </>,
  ],
];
