import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router";
import cx from "classnames";

import scrollToTop from "utils/scroll-to-top";
import { getData, isLoggedIn } from "utils/rails";

import Navigation from "components/navigation";

import css from "./index.module.css";

const Page = ({ children }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const hasPopped = useRef(false);
  const location = useLocation();

  const handlePopState = () => {
    hasPopped.current = true;
  };

  useEffect(() => {
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    if (hasPopped.current) {
      hasPopped.current = false;
    } else {
      scrollToTop();
    }
  }, [location]);

  const rootClasses = cx(
    "flex flex-column h-100",
    isMenuOpen && "overflow-hidden",
    css.root
  );
  const contentClasses = cx("flex-grow-1 h-100", css.content);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen((current) => !current);
  };

  return (
    <div className={rootClasses}>
      <Navigation
        yourProfilePath={getData("yourProfilePath")}
        isMenuOpen={isMenuOpen}
        isLoggedIn={isLoggedIn()}
        onMenuClick={toggleMenu}
      />
      <div className={contentClasses}>{children}</div>
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.node,
};

export default Page;
