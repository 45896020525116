import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";

import shuffle from "utils/shuffle";
import useScrollOnError from "hooks/scroll-on-error";

import ActionFlowContainer from "components/action-flow-container";
import CheckboxButtonGroup from "components/checkbox-button-group";
import FormErrors from "components/form-errors";

const QUALITIES_OPTIONS = shuffle([
  "Confidence",
  "Creativity",
  "Curiosity",
  "Discipline",
  "Diversity",
  "Empathy",
  "Faithfulness",
  "Generosity",
  "Honesty",
  "Humour",
  "Independence",
  "Intellect",
  "Joy",
  "Justice",
  "Kindness",
  "Resilience",
  "Respect",
  "Sociability",
  "Thoughtfulness",
]).map((value) => [value, value.toLowerCase()]);

const Form = ({
  backTo,
  submitLabel,
  handleSubmit,
  errors,
  setFieldValue,
  isSubmitting,
}) => {
  useScrollOnError(errors, isSubmitting);

  return (
    <ActionFlowContainer
      title="Which of the below qualities are most important to instill in your children?"
      description="You can choose up to 3. We won't rule anyone out that doesn't match with you, just show whether each family is broadly aligned with you"
      backTo={backTo}
      action={{
        children: submitLabel,
        disabled: isSubmitting,
        type: "button",
        onClick: handleSubmit,
      }}
    >
      <FormErrors className="mb3" errors={errors} />
      <Field
        name="qualities"
        error={errors.qualities}
        component={CheckboxButtonGroup}
        options={QUALITIES_OPTIONS}
        onChange={(qualities) => setFieldValue("qualities", qualities)}
        required
      />
    </ActionFlowContainer>
  );
};

Form.propTypes = {
  errors: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  backTo: PropTypes.object,
  submitLabel: PropTypes.string,
};

export default Form;
