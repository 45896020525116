import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import ReactModal from "react-modal";
import * as Sentry from "@sentry/browser";

import { setPage, trackPageView } from "utils/analytics";
import { getProps } from "utils/rails";

import "core.css";

import Page from "components/page";
import SentryBoundary from "components/sentry-boundary";

import AboutYouScreen from "screens/about-you-screen";
import BioScreen from "screens/bio-screen";
import ChildScreen from "screens/child-screen";
import CheckEmailScreen from "screens/check-email-screen";
import ConversationScreen from "screens/conversation-screen";
import ConversationsScreen from "screens/conversations-screen";
import CovidScreen from "screens/covid-screen";
import EditRequirementsScreen from "screens/edit-requirements-screen";
import EditScreen from "screens/edit-screen";
import ExpiredScreen from "screens/expired-screen";
import DaysScreen from "screens/days-screen";
import LandingScreen from "screens/landing-screen";
import LoginScreen from "screens/login-screen";
import ProfileScreen from "screens/profile-screen";
import QualitiesScreen from "screens/qualities-screen";
import ResultsScreen from "screens/results-screen";
import SignupScreen from "screens/signup-screen";
import SharingScreen from "screens/sharing-screen";
import StartDateScreen from "screens/start-date-screen";
import Onboarding from "screens/onboarding";

// eslint-disable-next-line react/display-name
const withDataProps = (Component, key) => () =>
  <Component {...getProps(key)} />;

if (process.env.SENTRY_CLIENT_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_CLIENT_DSN,
    environment: process.env.NODE_ENV,
  });
}

ReactModal.setAppElement("#app");

document.addEventListener("DOMContentLoaded", () => {
  const history = createBrowserHistory();

  history.listen((location) => {
    setPage(location.pathname);
    trackPageView();
  });

  ReactDOM.render(
    <SentryBoundary>
      <Router history={history} basename="/">
        <Page>
          <Switch>
            <Route path="/" exact component={LandingScreen} />;
            <Route path="/find-a-family" exact component={CovidScreen} />;
            <Route path="/login" exact component={LoginScreen} />;
            <Route path="/login/sent" component={CheckEmailScreen} />;
            <Route path="/expired" component={ExpiredScreen} />;
            <Route path="/onboarding" component={Onboarding} />
            <Route path="/signup/about-you" component={AboutYouScreen} />
            <Route
              path="/signup/children"
              render={() => (
                <SignupScreen
                  component={ChildScreen}
                  nextPath="start-date"
                  step={2}
                />
              )}
            />
            <Route
              path="/signup/start-date"
              render={() => (
                <SignupScreen
                  component={StartDateScreen}
                  prevPath="children"
                  nextPath="days"
                  step={3}
                />
              )}
            />
            <Route
              path="/signup/days"
              render={() => (
                <SignupScreen
                  component={DaysScreen}
                  prevPath="start-date"
                  nextPath="qualities"
                  step={4}
                />
              )}
            />
            <Route
              path="/signup/qualities"
              render={() => (
                <SignupScreen
                  component={QualitiesScreen}
                  prevPath="days"
                  nextPath="introduce-yourself"
                  step={5}
                  forceReload
                />
              )}
            />
            <Route
              path="/signup/introduce-yourself"
              render={() => (
                <SignupScreen
                  component={BioScreen}
                  prevPath="qualities"
                  nextPath="/signup/your-profile"
                  step={6}
                  forceReload
                  {...getProps()}
                />
              )}
            />
            <Route
              path="/signup/your-profile"
              render={() => (
                <SignupScreen
                  component={ProfileScreen}
                  prevPath="introduce-yourself"
                  nextPath="/local-families?signup=true"
                  step={7}
                  forceReload
                  {...getProps()}
                />
              )}
            />
            <Route path="/your-profile" render={withDataProps(ProfileScreen)} />
            <Route
              path="/local-families"
              exact
              render={withDataProps(ResultsScreen)}
            />
            <Route
              path="/:id/:name-family"
              render={withDataProps(ProfileScreen)}
            />
            <Route
              path="/conversations"
              exact
              render={withDataProps(ConversationsScreen)}
            />
            <Route
              path="/conversations/:id"
              render={withDataProps(ConversationScreen)}
            />
            <Route path="/share" render={withDataProps(SharingScreen)} />
            <Route
              path="/your-requirements"
              exact
              render={withDataProps(EditRequirementsScreen, "summaries")}
            />
            <Route
              path="/your-requirements/children"
              render={() => (
                <EditScreen component={ChildScreen} {...getProps("children")} />
              )}
            />
            <Route
              path="/your-requirements/start-date"
              render={() => (
                <EditScreen
                  component={StartDateScreen}
                  {...getProps("startDate")}
                />
              )}
            />
            <Route
              path="/your-requirements/days"
              render={() => (
                <EditScreen component={DaysScreen} {...getProps("days")} />
              )}
            />
            <Route
              path="/your-requirements/qualities"
              render={() => (
                <EditScreen
                  component={QualitiesScreen}
                  {...getProps("qualities")}
                />
              )}
            />
            <Route
              path="/your-requirements/introduce-yourself"
              render={() => (
                <EditScreen component={BioScreen} {...getProps("bio")} />
              )}
            />
          </Switch>
        </Page>
      </Router>
    </SentryBoundary>,
    document.getElementById("app")
  );
});
