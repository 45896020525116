import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import ProgressBar from "components/progress-bar";

const TOTAL_STEPS = 7;

const SignupScreen = ({
  component: Component,
  forceReload = false,
  nextPath,
  prevPath,
  step,
  ...rest
}) => {
  const history = useHistory();

  const handleSubmit = () => {
    if (forceReload) {
      window.location.assign(nextPath);
    } else {
      history.push(nextPath);
    }
  };

  return (
    <div className="mw6 center h-100">
      <Component
        {...rest}
        backTo={prevPath ? { href: prevPath } : null}
        onSubmit={handleSubmit}
        isSignup
      />
      <ProgressBar currentStep={step} totalSteps={TOTAL_STEPS} />
    </div>
  );
};

SignupScreen.propTypes = {
  component: PropTypes.elementType.isRequired,
  nextPath: PropTypes.string.isRequired,
  step: PropTypes.number.isRequired,
  forceReload: PropTypes.bool,
  prevPath: PropTypes.string,
};

export default SignupScreen;
