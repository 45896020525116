import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import InfoBox from "components/info-box";

import readingIcon from "images/icons/reading.svg";

const BioPrompt = ({ className }) => {
  const rootClasses = cx(className, "pa2 f7 lh-copy");

  return (
    <InfoBox className={rootClasses}>
      <div className="flex items-start">
        <img src={readingIcon} className="mr2" />
        <div>
          <p>
            As you didn’t add your own Bio, we’ve created one for you. If you’d
            like to change it, you can do so here:
          </p>
          <a
            href="/your-requirements/introduce-yourself"
            className="b underline"
          >
            Change your bio
          </a>
        </div>
      </div>
    </InfoBox>
  );
};

BioPrompt.propTypes = {
  className: PropTypes.string,
};

export default BioPrompt;
