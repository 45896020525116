import React from "react";
import PropTypes from "prop-types";

import css from "./index.module.css";

const ProgressBar = ({ currentStep, totalSteps }) => {
  const progressPercentage = (currentStep / totalSteps) * 100;

  return (
    <div className="w-100 mw6 center fixed bottom-0 z-2">
      <div className={css.root} style={{ width: `${progressPercentage}%` }} />
    </div>
  );
};

ProgressBar.propTypes = {
  currentStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
};

export default ProgressBar;
