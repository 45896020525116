import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import Button from "components/button";
import SlideshowCounter from "components/slideshow-counter";

import css from "./index.module.css";

const OnboardingStep = ({
  buttonText,
  currentStep,
  imageClasses,
  onClickNext,
  subTitle,
  title,
  totalSteps,
}) => {
  const wrapperClasses = cx("w-70 center w-50-m w-40-l", css.wrapper);
  const footerClasses =
    "flex-ns flex-column fixed static-ns bottom-0 left-0 w-100 tr pa3 pt4-ns ph0-ns";

  return (
    <div className={imageClasses}>
      <div className="mw8 center">
        <div className={wrapperClasses}>
          <h1 className="f2 lh-title f1-ns white mb3 mb4-ns tc tl-ns">
            {title}
          </h1>
          <h2 className="f2 lh-title f1-ns white tc tl-ns">{subTitle}</h2>
          <footer className={footerClasses}>
            <div className="flex-ns flex-row-reverse-ns">
              <Button className="f6 mt3 mt0-ns ml0-ns" onClick={onClickNext}>
                {buttonText}
              </Button>
              <SlideshowCounter
                totalSteps={totalSteps}
                currentStep={currentStep}
              />
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

OnboardingStep.propTypes = {
  buttonText: PropTypes.string.isRequired,
  currentStep: PropTypes.number.isRequired,
  imageClasses: PropTypes.string.isRequired,
  totalSteps: PropTypes.number.isRequired,
  onClickNext: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
};

export default OnboardingStep;
