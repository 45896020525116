import React from "react";
import { useLocation } from "react-router-dom";

import ActionFlowContainer from "components/action-flow-container";

const CheckEmailScreen = () => {
  const location = useLocation();
  const { email } = location.state;

  return (
    <div className="mw6 center h-100">
      <ActionFlowContainer title="Check email">
        <div>
          To confirm your email address, tap the button in the email we sent to
        </div>
        <div className="mt2 b">{email}</div>
      </ActionFlowContainer>
    </div>
  );
};

export default CheckEmailScreen;
