import React from "react";
import PropTypes from "prop-types";
import { groupWith } from "ramda";
import { differenceInSeconds } from "date-fns/fp";

import dateToWords from "utils/date-to-words";

import Pill from "./pill";
import MessagesByAuthor from "./messages-by-author";

const groupMessages = groupWith(
  (message, next) =>
    message.selfAuthored === next.selfAuthored &&
    differenceInSeconds(message.sentAt, next.sentAt) <= 120
);

const MessagesByDate = ({ date, messages }) => {
  const groupedMessages = groupMessages(messages);
  return (
    <div>
      <Pill className="mb2">
        <span className="mh1">{dateToWords(date)}</span>
      </Pill>
      {groupedMessages.map((messageGroup) => (
        <MessagesByAuthor
          date={messageGroup[0].sentAt}
          key={`${messageGroup[0].hashid}`}
          messages={messageGroup}
        />
      ))}
    </div>
  );
};

MessagesByDate.propTypes = {
  date: PropTypes.instanceOf(Date),
  messages: PropTypes.array,
};

export default MessagesByDate;
