import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import FamilyProperties from "components/family-properties";

import css from "./index.module.css";

const ReferralCard = ({ className, familyName, ...rest }) => {
  const rootClasses = cx(css.boxShadow, "b--light-gray ba br2", className);

  return (
    <div className={rootClasses}>
      <div className="tl pa3 br2 lh-copy purple bg-white">
        <h3 className="b f3 normal">{familyName}</h3>
        <FamilyProperties {...rest} />
      </div>
    </div>
  );
};

ReferralCard.propTypes = {
  familyName: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ReferralCard;
