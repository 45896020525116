import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import cx from "classnames";

import Select from "components/select";

import css from "./index.module.css";

const MONTH_NAMES = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const DAYS = Array.from(Array(31), (_, d) => d + 1);
const MONTHS = Array.from(Array(12), (_, m) => [MONTH_NAMES[m], m + 1]);

const DateOfBirth = ({ namePrefix, error }) => {
  const rootClasses = cx(error && css.error);
  const labelClasses = cx("pv1 mb1 purple f6 mb1 dib mt4");

  const NOW = new Date();
  const YEARS = Array.from(Array(15), (_, y) => NOW.getFullYear() + 2 - y);

  return (
    <div className={rootClasses}>
      <label className={labelClasses}>Date of birth / Due date</label>
      <div className={css.row}>
        <div className={`${css.cell} w-third`}>
          <Field
            name={`${namePrefix}day`}
            aria-label="Day"
            component={Select}
            selectClassName="w-100"
            placeholder="Day"
            options={DAYS}
          />
        </div>
        <div className={`${css.cell} w-third`}>
          <Field
            name={`${namePrefix}month`}
            aria-label="Month"
            component={Select}
            selectClassName="w-100"
            placeholder="Month"
            options={MONTHS}
          />
        </div>
        <div className={`${css.cell} w-third`}>
          <Field
            name={`${namePrefix}year`}
            aria-label="Year"
            component={Select}
            selectClassName="w-100"
            placeholder="Year"
            options={YEARS}
          />
        </div>
      </div>
      {error && <div className="f6 mt1 pt1 orange">{error}</div>}
    </div>
  );
};

DateOfBirth.propTypes = {
  namePrefix: PropTypes.string.isRequired,
  error: PropTypes.string,
};

export default DateOfBirth;
