import React from "react";
import PropTypes from "prop-types";
import { includes, symmetricDifference } from "ramda";

import ToggleButtonGroup from "components/toggle-button-group";

const CheckboxButtonGroup = ({
  field = {},
  name = field.name,
  value = field.value,
  onChange = field.onChange,
  ...rest
}) => {
  const handleChange = (e) =>
    onChange(symmetricDifference(value, [e.target.value]));

  return (
    <ToggleButtonGroup
      isChecked={(key) => includes(key, value)}
      name={name}
      value={value}
      type="checkbox"
      onChange={handleChange}
      {...rest}
    />
  );
};

CheckboxButtonGroup.propTypes = {
  field: PropTypes.object,
  name: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
};

export default CheckboxButtonGroup;
