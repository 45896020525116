import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import Button from "components/button";

const ToggleButton = ({
  checked,
  label,
  buttonClassName,
  className,
  ...rest
}) => {
  const buttonClasses = cx("w-100 f6", buttonClassName);

  return (
    <label className={className}>
      <input className="dn" checked={checked} {...rest} />
      <Button
        component="div"
        style={checked ? "toggled" : "outline"}
        className={buttonClasses}
      >
        {label}
      </Button>
    </label>
  );
};

ToggleButton.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  buttonClassName: PropTypes.string,
  className: PropTypes.string,
};

export default ToggleButton;
