import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { analyticsEvent, fbEvent } from "utils/analytics";

import FamilyCard from "components/family-card";
import InfoSheet from "components/info-sheet";

import locationSearchIcon from "images/icons/location-search.svg";

import BioPrompt from "./components/bio-prompt";
import FamilyList from "./components/family-list";
import FlexResults from "./components/flex-results";
import NannyPrompt from "./components/nanny-prompt";
import ReferralSheet from "./components/referral-sheet";
import SharePrompt from "./components/share-prompt";

const ResultsScreen = ({
  families,
  familiesFlexStartDate = [],
  familiesFlexDistance = [],
  referrer,
  isAfterSignup = false,
  showBioPrompt = false,
  hasReactivated = false,
}) => {
  const [isReferralOpen, setReferrerOpen] = useState(true);
  const [isReactivatedOpen, setReactivatedOpen] = useState(true);

  const handleReferralClose = () => setReferrerOpen(false);
  const handleReactivatedClose = () => setReactivatedOpen(false);

  const totalFamilies = families.length;
  const headline = `${totalFamilies} ${
    totalFamilies === 1 ? "family matches" : "families match"
  } your requirements based on your start date & location`;

  useEffect(() => {
    analyticsEvent("Local families", "Viewed results", `${totalFamilies}`);
  }, [totalFamilies]);

  useEffect(() => {
    if (isAfterSignup) {
      fbEvent("CompleteRegistration");
    }
  }, [isAfterSignup]);

  const [firstFamily, ...otherFamilies] = families;

  return (
    <>
      <div className="mw6 center h-100">
        <div className="pa4-ns pa3">
          <h2 className="normal f1-ns f2 lh-title">{headline}</h2>
          <a href="/your-requirements" className="no-underline b mt2 db">
            Edit your requirements
          </a>
        </div>
        {showBioPrompt && <BioPrompt className="ma4-ns ma3 mt0 mt0-ns" />}
        {firstFamily && (
          <FamilyCard key={firstFamily.hashid} {...firstFamily} />
        )}
        <NannyPrompt className="ma4-ns ma3" totalFamilies={totalFamilies} />
        {totalFamilies > 0 && <FamilyList families={otherFamilies} />}
        <SharePrompt className="ma4-ns ma3" totalFamilies={totalFamilies} />
        {familiesFlexStartDate.length > 0 && (
          <FlexResults
            type="startDate"
            families={familiesFlexStartDate}
            title={
              <>
                The following families would match if you could flex on your{" "}
                <strong className="green">start date</strong>
              </>
            }
          />
        )}
        {familiesFlexDistance.length > 0 && (
          <FlexResults
            type="distance"
            families={familiesFlexDistance}
            title={
              <>
                The following families match but are{" "}
                <strong className="green">further away</strong>
              </>
            }
          />
        )}
      </div>
      {hasReactivated && (
        <InfoSheet
          isOpen={isReactivatedOpen}
          title="Search resumed"
          message="Other families can now find you and get in touch"
          iconSrc={locationSearchIcon}
          onRequestClose={handleReactivatedClose}
        />
      )}
      {referrer && (
        <ReferralSheet
          isOpen={isReferralOpen}
          onRequestClose={handleReferralClose}
          {...referrer}
        />
      )}
    </>
  );
};

ResultsScreen.propTypes = {
  families: PropTypes.array.isRequired,
  familiesFlexStartDate: PropTypes.array,
  familiesFlexDistance: PropTypes.array,
  hasReactivated: PropTypes.bool,
  isAfterSignup: PropTypes.bool,
  referrer: PropTypes.object,
  showBioPrompt: PropTypes.bool,
};

export default ResultsScreen;
