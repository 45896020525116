import React, { useEffect } from "react";
import cx from "classnames";
import { Link } from "react-router-dom";

import { fbEvent } from "utils/analytics";
import { isLoggedIn } from "utils/rails";

import Accordion from "components/accordion";
import Button from "components/button";

import IntroVideo from "./components/intro-video";
import faqOptions from "./faq-options";

import css from "./index.module.css";

const LandingScreen = () => {
  const imageClasses = cx("pl3 pt4 pt5-ns z-1", css.image);
  const solidClasses = cx("pl3 flex-grow-1 pv3", css.solid);

  useEffect(() => {
    fbEvent("ViewContent");
  }, []);

  return (
    <div className="min-h-100 flex flex-column">
      <div className={imageClasses}>
        <div className="mw8 center pr3">
          <div className="w-80 w-60-m w-40-l">
            <h1 className="f1-ns f2 white mb2-ns">
              Find a local family to share a nanny with
            </h1>
            <h2 className="f4 f2-ns white normal mt1">
              Share the costs and get a playmate for your child
            </h2>
          </div>
          <div className="mt3 w-60-m w-40-l">
            <div className="mt2">
              <IntroVideo />
            </div>
          </div>
        </div>
        <div className={css.angledBorder} />
      </div>
      <div className={solidClasses}>
        <div className="mw8 center pr3">
          <div className="w-60-m w-40-l">
            <div className="pb3 tr">
              {!isLoggedIn() && (
                <>
                  <a href="/login" className="f6 white mt3">
                    Existing user? Login
                  </a>
                  <Button
                    component={Link}
                    className="f6 mt3 ml3"
                    to="/onboarding"
                  >
                    Find families
                  </Button>
                </>
              )}
            </div>
            <h2 className="f3 f2-ns white mb3">Want to learn more?</h2>
            <Accordion options={faqOptions} />
            <div className="white lh-title mt4">
              <p className="mb2">
                Find a great local family to share a nanny with for your baby or
                toddler.
              </p>
              <p className="mb2">
                Sharing a nanny with another family costs around £100 per day,
                and means your child has a little playmate to learn and grow
                with.
              </p>
              <p>
                First, we’ll find you a great family to share with. Then, if you
                need a one, we’ll get a fabulous qualified, background checked
                and Ofsted registered nanny.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingScreen;
