import { mapObjIndexed, forEachObjIndexed, keys } from "ramda";

const indexedObjectToArray = (indexedObject) => {
  const maxIndex = parseInt(
    keys(indexedObject).reduce((a, b) => Math.max(a, b)),
    10
  );

  const array = Array(maxIndex + 1);

  forEachObjIndexed((value, index) => {
    array[parseInt(index, 10)] = value;
  }, indexedObject);

  return array;
};

const transformErrors = (errors) => {
  return mapObjIndexed((messages) => {
    if (Array.isArray(messages)) {
      return messages[0];
    }

    return indexedObjectToArray(messages).map(transformErrors);
  }, errors);
};

export default transformErrors;
