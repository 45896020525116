import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import dateToWords from "utils/date-to-words";
import arrowIcon from "images/icons/arrow.svg";

const ConversationRow = ({
  hashid,
  otherFamilyName,
  latestMessage: { truncatedBody, createdAt, read },
}) => {
  const rowColor = read ? "gray" : "purple";
  const conversationPath = `/conversations/${hashid}`;
  const unreadIndicator = !read && (
    <div className="ba bw2 br-100 b--rose dib mr2"> </div>
  );
  const msgDate = new Date(createdAt * 1000);
  const formattedCreatedAt = dateToWords(msgDate);

  return (
    <a href={conversationPath} className="no-underline purple">
      <div className={cx("mb2 ph3 bb b--near-white", rowColor)}>
        {unreadIndicator}
        <span className={cx("f5 mb2", !read && "b")}>{otherFamilyName}</span>
        <img src={arrowIcon} className="fr cf:after mt1" />
        <div className="f6 mt2">{truncatedBody}</div>
        <div className="tr f7 mb1 gray">{formattedCreatedAt}</div>
      </div>
    </a>
  );
};

ConversationRow.propTypes = {
  hashid: PropTypes.string.isRequired,
  otherFamilyName: PropTypes.string.isRequired,
  latestMessage: PropTypes.object.isRequired,
};

export default ConversationRow;
