import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const IconRow = ({ children, icon, label, isHighlit = false }) => {
  const valueClasses = cx("b ml1 ph1 f6", isHighlit && "bg-light-green");

  return (
    <div className="flex items-start mb1">
      <img className="mr2" src={icon} />
      <div className="f6">{label}</div>
      <div className={valueClasses}>{children}</div>
    </div>
  );
};

IconRow.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isHighlit: PropTypes.bool,
};

export default IconRow;
