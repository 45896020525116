import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import css from "./index.module.css";

const InfoBox = ({ className, children }) => {
  const rootClasses = cx(css.root, className, "br2 bg-not-white medium-gray");

  return <div className={rootClasses}>{children}</div>;
};

InfoBox.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default InfoBox;
