import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { isEmpty } from "ramda";

import { postJson } from "utils/fetch";
import scrollToTop from "utils/scroll-to-top";
import transformErrors from "utils/transform-errors";

import Form from "./components/form";

const BioScreen = ({
  onSubmit,
  backTo,
  isSignup = false,
  initialBio = "",
  submitLabel = "View your profile",
}) => {
  return (
    <Formik
      initialValues={{ bio: initialBio }}
      onSubmit={async ({ bio }, { setErrors, setSubmitting }) => {
        const { errors = {} } = await postJson("/api/bios", {
          bio,
        });

        setSubmitting(false);

        if (isEmpty(errors)) {
          onSubmit();
        } else {
          setErrors(transformErrors(errors));
          scrollToTop();
        }
      }}
    >
      {(formikProps) => (
        <Form
          backTo={backTo}
          submitLabel={submitLabel}
          isSignup={isSignup}
          {...formikProps}
        />
      )}
    </Formik>
  );
};

BioScreen.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  backTo: PropTypes.object,
  initialBio: PropTypes.string,
  isSignup: PropTypes.bool,
  submitLabel: PropTypes.string,
};

export default BioScreen;
