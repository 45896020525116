import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { isEmpty } from "ramda";

import { postJson } from "utils/fetch";
import transformErrors from "utils/transform-errors";

import ProgressBar from "components/progress-bar";

import Form from "./components/form";

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required("Please provide your first name"),
  lastName: Yup.string().required("Please provide your last name"),
  email: Yup.string()
    .email("Please provide a valid email address")
    .required("Please provide your email address"),
  postcode: Yup.string().required("Please provide your postcode"),
});

const AboutYouScreen = () => {
  return (
    <div className="mw6 center h-100">
      <Formik
        initialValues={{ firstName: "", lastName: "", email: "", postcode: "" }}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={SignupSchema}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          const { errors = {} } = await postJson("/users", { user: values });

          setSubmitting(false);

          if (isEmpty(errors)) {
            window.location.assign("/signup/children");
          } else {
            setErrors(transformErrors(errors));
          }
        }}
      >
        {(formikProps) => <Form {...formikProps} />}
      </Formik>
      <ProgressBar currentStep={1} totalSteps={6} />
    </div>
  );
};

export default AboutYouScreen;
