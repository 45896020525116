import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import backIcon from "images/icons/back.svg";

const Navigation = ({ href, push = true }) => {
  const linkClasses = "dib";

  return push ? (
    <Link className={linkClasses} to={href}>
      <img alt="Back" src={backIcon} />
    </Link>
  ) : (
    <a className={linkClasses} href={href}>
      <img alt="Back" src={backIcon} />
    </a>
  );
};

Navigation.propTypes = {
  href: PropTypes.string.isRequired,
  push: PropTypes.bool,
};

export default Navigation;
