import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { isEmpty } from "ramda";

import { postJson } from "utils/fetch";
import scrollToTop from "utils/scroll-to-top";
import transformErrors from "utils/transform-errors";

import Form from "./components/form";

const SignupSchema = Yup.object().shape({
  qualities: Yup.array()
    .ensure()
    .required("Please choose at least one quality that is important to you")
    .max(3, "Please only choose up to 3 qualities that are important to you"),
});

const QualitiesScreen = ({
  onSubmit,
  backTo,
  initialQualities = [],
  submitLabel = "Next",
}) => {
  return (
    <Formik
      initialValues={{ qualities: initialQualities }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={SignupSchema}
      onSubmit={async ({ qualities }, { setErrors, setSubmitting }) => {
        const { errors = {} } = await postJson("/api/qualities", {
          qualities,
        });

        setSubmitting(false);

        if (isEmpty(errors)) {
          onSubmit();
        } else {
          setErrors(transformErrors(errors));
          scrollToTop();
        }
      }}
    >
      {(formikProps) => (
        <Form backTo={backTo} submitLabel={submitLabel} {...formikProps} />
      )}
    </Formik>
  );
};

QualitiesScreen.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  backTo: PropTypes.object,
  initialQualities: PropTypes.array,
  submitLabel: PropTypes.string,
};

export default QualitiesScreen;
