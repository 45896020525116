import React from "react";
import PropTypes from "prop-types";

import FamilyCard from "components/family-card";

const FamilyList = ({ families, ...rest }) => {
  return (
    <div>
      {families.map((family) => (
        <FamilyCard key={family.hashid} {...family} {...rest} />
      ))}
    </div>
  );
};

FamilyList.propTypes = {
  families: PropTypes.array.isRequired,
};

export default FamilyList;
