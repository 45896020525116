import React, { useState } from "react";
import PropTypes from "prop-types";
import { includes, symmetricDifference } from "ramda";

import Row from "./row.js";

const Accordion = ({ options }) => {
  const [activeRows, setActiveRows] = useState([]);

  const toggleRow = (idx) => {
    setActiveRows((currentRows) => symmetricDifference(currentRows, [idx]));
  };

  return (
    <div>
      {options.map(([title, content], idx) => (
        <Row
          key={title}
          title={title}
          content={content}
          isOpen={includes(idx, activeRows)}
          onRequestOpen={() => toggleRow(idx)}
        />
      ))}
    </div>
  );
};

Accordion.propTypes = {
  options: PropTypes.array.isRequired,
};

export default Accordion;
