import { camelCaseKeys } from "utils/transform-object-keys";

const getData = (key) => {
  const container = document.getElementById("app");

  return container.dataset[key];
};

const getProps = (key) => {
  const container = document.getElementById("app");
  const props = camelCaseKeys(JSON.parse(container.dataset.props));

  if (key) {
    return props[key];
  }

  return props;
};

const isLoggedIn = () => {
  const container = document.getElementById("app");

  return "isLoggedIn" in container.dataset;
};

export { getData, getProps, isLoggedIn };
