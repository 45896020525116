import React from "react";

import ActionFlowContainer from "components/action-flow-container";

const ExpiredScreen = () => {
  return (
    <div className="mw6 center h-100">
      <ActionFlowContainer
        title="This link has expired"
        description="We've just emailed you a fresh link which will log you in"
        className="tc mt4"
      ></ActionFlowContainer>
    </div>
  );
};

export default ExpiredScreen;
