import React from "react";
import PropTypes from "prop-types";

import ActionFlowContainer from "components/action-flow-container";

import babyIcon from "images/icons/baby.svg";
import bioIcon from "images/icons/bio.svg";
import dateIcon from "images/icons/date.svg";
import daysIcon from "images/icons/days.svg";
import qualitiesIcon from "images/icons/qualities.svg";

import Row from "./components/row";

const EditRequirementsScreen = ({
  children,
  startDate,
  days,
  qualities,
  bio,
}) => {
  return (
    <div className="mw6 center h-100">
      <ActionFlowContainer
        title="Edit your requirements"
        description="We'll do our best to find families that match the below criteria"
        backTo={{ href: "/local-families", push: false }}
        action={{
          children: "Show families",
          href: "/local-families",
          component: "a",
        }}
      >
        <Row
          icon={babyIcon}
          to="children"
          label="Children"
          summary={children}
        />
        <Row
          icon={dateIcon}
          to="start-date"
          label="Start date"
          summary={startDate}
        />
        <Row icon={daysIcon} to="days" label="Days" summary={days} />
        <Row
          icon={qualitiesIcon}
          to="qualities"
          label="Qualities"
          summary={qualities}
        />
        <Row
          icon={bioIcon}
          to="introduce-yourself"
          label="Biography"
          summary={bio}
        />
      </ActionFlowContainer>
    </div>
  );
};

EditRequirementsScreen.propTypes = {
  bio: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  days: PropTypes.string.isRequired,
  qualities: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
};

export default EditRequirementsScreen;
