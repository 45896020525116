import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import css from "./row.module.css";

const Row = ({ title, content, onRequestOpen, isOpen = false }) => {
  const rootClasses = cx(
    css.root,
    isOpen && css.isOpen,
    isOpen && "pv2",
    !isOpen && "bt b--mid-gray"
  );
  const innerClasses = cx(css.inner);
  const titleClasses = cx(
    css.title,
    "f5 pv2 pl3 pr4 b db no-underline white pointer relative"
  );
  const contentClasses = cx(
    css.content,
    "white f6 ph3 lh-title",
    isOpen && css.isOpen
  );
  const contentRef = useRef();
  const rootRef = useRef();

  const handleTitleClick = (e) => {
    e.preventDefault();
    onRequestOpen();
  };

  useEffect(() => {
    contentRef.current.style.height = isOpen
      ? `${contentRef.current.scrollHeight}px`
      : 0;

    if (isOpen && "scrollIntoViewIfNeeded" in rootRef.current) {
      setTimeout(() => {
        rootRef.current.scrollIntoViewIfNeeded();
      }, 100);
    }
  }, [isOpen]);

  return (
    <div ref={rootRef} className={rootClasses}>
      <div className={innerClasses}>
        <a href="#" className={titleClasses} onClick={handleTitleClick}>
          {title}
        </a>
        <div className={contentClasses} ref={contentRef}>
          <div className="pt1 pb3">{content}</div>
        </div>
      </div>
    </div>
  );
};

Row.propTypes = {
  content: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  onRequestOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

export default Row;
