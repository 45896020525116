import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";

import Button from "components/button";

import tick from "./tick-burst.svg";

import css from "./index.module.css";

const OkieDoke = ({ title, message }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleCloseClick = () => setIsOpen(false);

  return (
    <ReactModal
      isOpen={isOpen}
      className={{
        base: `${css.root} absolute z-4 absolute--fill outline-0 flex flex-column pa3 w100 mw6 center purple`,
        afterOpen: "",
        beforeClose: "",
      }}
      bodyOpenClassName="overflow-hidden"
      overlayClassName={{
        base: `${css.overlay} fixed top-0 bottom-0 right-0 left-0 z-3 bg-white-80`,
        afterOpen: css.overlayAfterOpen,
        beforeClose: css.overlayBeforeClose,
      }}
      closeTimeoutMS={200}
    >
      <div className="center flex flex-column justify-center items-center tc">
        <img src={tick} className={css.tick} height="100" width="100" />
        <h1 className={`${css.title} mb3 medium-gray`}>{title}</h1>
        <p className={`${css.message} f3 tc lh-copy measure-narrow ph3`}>
          {message}
        </p>
        <div
          className={`${css.buttonWrapper} fixed bottom-0 w-100 mw6 pr3 pb3 tr`}
        >
          <Button className="f6" onClick={handleCloseClick}>
            Got it
          </Button>
        </div>
      </div>
    </ReactModal>
  );
};

OkieDoke.propTypes = {
  title: PropTypes.node.isRequired,
  message: PropTypes.node.isRequired,
};

export default OkieDoke;
