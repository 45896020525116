import React from "react";
import PropTypes from "prop-types";

import BottomSheet from "components/bottom-sheet";
import Button from "components/button";

const InfoSheet = ({ isOpen, iconSrc, title, message, onRequestClose }) => {
  return (
    <BottomSheet isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className="w-100 mw7 center mb6-l bg-not-white pa3 pt4 cf">
        <div className="fl w-10 tc pt1 tr">
          <img src={iconSrc} />
        </div>
        <div className="fl w-90 pl2 dark-gray">
          <h3 className="pv1 lh-title f5">{title}</h3>
          <div className="f6 pv1 measure lh-copy break-word mb4">{message}</div>
          <div className="tr">
            <Button className="f6" onClick={onRequestClose}>
              Got it
            </Button>
          </div>
        </div>
      </div>
    </BottomSheet>
  );
};

InfoSheet.propTypes = {
  iconSrc: PropTypes.string,
  isOpen: PropTypes.bool,
  message: PropTypes.node,
  title: PropTypes.string,
  onRequestClose: PropTypes.func,
};

export default InfoSheet;
