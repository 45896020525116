import React from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { isEmpty } from "ramda";
import { useHistory } from "react-router-dom";

import { postJson } from "utils/fetch";
import Input from "components/input";
import transformErrors from "utils/transform-errors";

import ActionFlowContainer from "components/action-flow-container";

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please provide a valid email address")
    .required("Please provide your email address"),
});

const LoginScreen = () => {
  const history = useHistory();

  return (
    <div className="mw6 center h-100">
      <Formik
        initialValues={{ email: "" }}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={LoginSchema}
        onSubmit={async ({ email }, { setErrors, setSubmitting }) => {
          const { errors = {} } = await postJson("/login", { email });

          setSubmitting(false);

          if (isEmpty(errors)) {
            history.push("/login/sent", { email });
          } else {
            setErrors(transformErrors(errors));
          }
        }}
      >
        {({ handleSubmit, errors, isSubmitting }) => (
          <ActionFlowContainer
            title="Log in"
            description="Enter your email address and we'll send you a quick email to confirm it."
            action={{
              children: "Next",
              disabled: isSubmitting,
              type: "button",
              onClick: handleSubmit,
            }}
          >
            <form onSubmit={handleSubmit}>
              <div>
                <Field
                  type="email"
                  name="email"
                  label="Email"
                  error={errors.email}
                  component={Input}
                  required
                />
              </div>
            </form>
          </ActionFlowContainer>
        )}
      </Formik>
    </div>
  );
};

export default LoginScreen;
