import React from "react";
import PropTypes from "prop-types";

import BottomSheet from "components/bottom-sheet";
import Button from "components/button";

import bioIcon from "images/icons/bio.svg";

import ReferralCard from "./components/referral-card";

import css from "./index.module.css";

const downcaseFirst = (input) =>
  input.charAt(0).toLowerCase() + input.substr(1);

const ReferralSheet = ({
  isOpen,
  onRequestClose,
  familyName,
  profilePath,
  ...rest
}) => {
  return (
    <BottomSheet isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className="w-100 mw7 center mb6-l bg-not-white">
        <div className="flex pt4 ph3">
          <div className="pt1 flex-shrink-0">
            <img className={css.icon} src={bioIcon} />
          </div>
          <div className="pl2 medium-gray">
            <h3 className="pv1 lh-title">This family invited you</h3>
            <p className="f6 pv1 measure lh-copy break-word">
              You responded to {downcaseFirst(familyName)}’s invite when you
              signed up.
            </p>
            <p className="f6 pv1 measure lh-copy break-word">
              Unfortunately you’re not a match with this family because of
              either your <strong>start date</strong>,{" "}
              <strong>essential days</strong>, or <strong>location</strong>.
            </p>
            <ReferralCard
              className="dn db-ns mv2"
              familyName={familyName}
              {...rest}
            />
          </div>
        </div>
        <ReferralCard
          className="db dn-ns ma2"
          familyName={familyName}
          {...rest}
        />
        <div className="tr pa3 pt2">
          <Button
            component="a"
            href={profilePath}
            style="text"
            className="f6"
            onClick={onRequestClose}
          >
            View profile
          </Button>
          <Button className="f6" onClick={onRequestClose}>
            See my other matches
          </Button>
        </div>
      </div>
    </BottomSheet>
  );
};

ReferralSheet.propTypes = {
  familyName: PropTypes.string.isRequired,
  profilePath: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
};

export default ReferralSheet;
