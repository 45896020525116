import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const Header = ({ className = "mb4", title, description }) => {
  const rootClasses = cx("mt3", className);

  return (
    <div className={rootClasses}>
      {title && <h2 className="lh-title mb2 purple">{title}</h2>}
      {description && (
        <div className="f5 medium-gray lh-title">{description}</div>
      )}
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  description: PropTypes.node,
  title: PropTypes.string,
};

export default Header;
