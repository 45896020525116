import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import uniqid from "uniqid";

import { postJson, fetchJson } from "utils/fetch";

import ActionFlowContainer from "components/action-flow-container";
import MessageInput from "./components/message-input";
import MessageList from "./components/message-list";

const createMessage = (body) => ({
  hashid: uniqid(),
  body,
  sentAt: new Date(),
  selfAuthored: true,
  isPending: true,
});

const deserializeMessage = (message) => ({
  ...message,
  sentAt: new Date(message.sentAt * 1000),
});

const updateMessage = (hashid, newMessage) => (messages) => {
  return messages.map((message) => {
    if (message.hashid === hashid) {
      return deserializeMessage(newMessage);
    }

    return message;
  });
};

const ConversationScreen = ({ familyName, hashid, profilePath }) => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchMessages = async () => {
      const { messages: fetchedMessages } = await fetchJson(
        `/api/conversations/${hashid}/messages`
      );
      setMessages((currentMessages) => [
        ...fetchedMessages.map(deserializeMessage),
        ...currentMessages,
      ]);
    };

    fetchMessages();
  }, [hashid]);

  const handleMessageSubmit = async (messageText) => {
    const newMessage = createMessage(messageText);

    setMessages((currentMessages) => [...currentMessages, newMessage]);

    const { message: persistedMessage } = await postJson(
      `/api/conversations/${hashid}/messages`,
      {
        message: newMessage,
      }
    );

    setMessages(updateMessage(newMessage.hashid, persistedMessage));
  };

  return (
    <div className="mw6 center h-100">
      <ActionFlowContainer
        title={familyName}
        backTo={{ href: "/conversations", push: false }}
        description={
          <a href={profilePath} className="no-underline b">
            View their profile
          </a>
        }
      >
        <MessageList messages={messages} />
        <MessageInput onSubmit={handleMessageSubmit} />
      </ActionFlowContainer>
    </div>
  );
};

ConversationScreen.propTypes = {
  familyName: PropTypes.string.isRequired,
  hashid: PropTypes.string.isRequired,
  profilePath: PropTypes.string.isRequired,
};

export default ConversationScreen;
