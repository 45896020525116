import "whatwg-fetch";

import { getCsrfToken } from "./csrf.js";
import { camelCaseKeys, snakeCaseKeys } from "./transform-object-keys.js";

const handleJsonResponse = (request) =>
  request
    .then((response) => {
      if (response.ok || response.status === 422) return response;

      return Promise.reject(response);
    })
    .then((response) => response.json())
    .then(camelCaseKeys);

const withDefaults = (options) => ({
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    "X-CSRF-Token": getCsrfToken(),
  },
  cache: "no-cache",
  credentials: "same-origin",
  redirect: "follow",
  ...options,
});

const postJson = (url, data = {}) =>
  handleJsonResponse(
    fetch(
      url,
      withDefaults({
        method: "POST",
        body: JSON.stringify(snakeCaseKeys(data)),
      })
    )
  );

const fetchJson = (url) =>
  handleJsonResponse(
    fetch(url, {
      method: "GET",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
      },
    })
  );

const deleteJson = (url) => fetch(url, withDefaults({ method: "DELETE" }));

export { postJson, fetchJson, deleteJson };
