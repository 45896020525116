import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { format, setDate, addMonths } from "date-fns/fp";

import useScrollOnError from "hooks/scroll-on-error";

import ActionFlowContainer from "components/action-flow-container";
import Checkbox from "components/checkbox";
import FormErrors from "components/form-errors";
import Select from "components/select";

const displayFormat = format("MMMM yyyy");
const valueFormat = format("yyyy-MM-dd");

const Form = ({
  backTo,
  submitLabel,
  values: { startEarlier, startDate, startLater },
  handleSubmit,
  setFieldValue,
  errors,
  isSubmitting,
}) => {
  useScrollOnError(errors, isSubmitting);

  const now = setDate(1, new Date());
  const monthOptions = Array.from(Array(24), (_, m) => [
    displayFormat(addMonths(m, now)),
    valueFormat(addMonths(m, now)),
  ]);
  const laterMonthOptions = Array.from(Array(27), (_, m) => [
    displayFormat(addMonths(m, now)),
    valueFormat(addMonths(m, now)),
  ]);

  const nowValue = valueFormat(now);

  useEffect(() => {
    if (startDate === nowValue) {
      setFieldValue("startEarlier", false);
      setFieldValue("startDateMin", nowValue);
    }
  }, [startDate, setFieldValue, nowValue]);

  const canStartEarlier = startDate !== nowValue;

  return (
    <ActionFlowContainer
      title="When would you like your childcare to start?"
      backTo={backTo}
      action={{
        children: submitLabel,
        disabled: isSubmitting,
        type: "button",
        onClick: handleSubmit,
      }}
    >
      <FormErrors className="mb3" errors={errors} />
      <form onSubmit={handleSubmit}>
        <div>
          <Field
            name="startDate"
            label="Start month"
            options={monthOptions}
            selectClassName="w-100"
            error={errors.startDate}
            component={Select}
            required
          />
        </div>
        <h3 className="mt4 mb2">How flexible can you be?</h3>
        <div className="f5 medium-gray lh-title">
          The more flexible you can be, the more families you’ll have to match
          with
        </div>
        <div className="mt4">
          <Field
            name="startEarlier"
            component={Checkbox}
            style="large"
            label="The share can start earlier"
            labelClassName="b"
            disabled={!canStartEarlier}
          />
        </div>
        {startEarlier && (
          <div className="mt2">
            <Field
              name="startDateMin"
              options={monthOptions}
              selectClassName="w-100"
              error={errors.startDateMin}
              component={Select}
              aria-label="Earlier start month"
            />
          </div>
        )}
        <div className="mt4">
          <Field
            name="startLater"
            component={Checkbox}
            style="large"
            label="The share can start later"
            labelClassName="b"
          />
        </div>
        {startLater && (
          <div className="mt2">
            <Field
              name="startDateMax"
              options={laterMonthOptions}
              selectClassName="w-100"
              error={errors.startDateMax}
              component={Select}
              aria-label="Later start month"
            />
          </div>
        )}
      </form>
    </ActionFlowContainer>
  );
};

Form.propTypes = {
  errors: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  backTo: PropTypes.object,
  submitLabel: PropTypes.string,
};

export default Form;
