import React from "react";
import PropTypes from "prop-types";

import ConversationRow from "./components/conversation-row";

const ConversationsScreen = ({ conversations }) => {
  return (
    <div className="mw6 center h-100">
      <h2 className="pt4 mb4 lh-title purple ph3">Messages</h2>
      {conversations.length > 0 ? (
        conversations.map((conversation) => (
          <ConversationRow key={conversation.hashid} {...conversation} />
        ))
      ) : (
        <div className="ph3">No messages</div>
      )}
    </div>
  );
};

ConversationsScreen.propTypes = {
  conversations: PropTypes.array.isRequired,
};

export default ConversationsScreen;
