import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";

import useScrollOnError from "hooks/scroll-on-error";

import ActionFlowContainer from "components/action-flow-container";
import FormErrors from "components/form-errors";
import TextArea from "components/text-area";

const Form = ({
  backTo,
  submitLabel,
  handleSubmit,
  errors,
  isSignup = false,
  isSubmitting,
}) => {
  useScrollOnError(errors, isSubmitting);

  let description =
    "Other families find it useful to hear about your family’s hobbies, if you smoke, household pets, your beliefs, children’s allergies & any special needs. It can also be handy to add more nuanced info about your start date, and the days you need care.";

  if (isSignup) {
    description += " We’ve added a suggestion that you can change.";
  }

  return (
    <ActionFlowContainer
      title="Introduce your family"
      description={description}
      backTo={backTo}
      action={{
        children: submitLabel,
        disabled: isSubmitting,
        type: "button",
        onClick: handleSubmit,
      }}
    >
      <FormErrors className="mb3" errors={errors} />
      <Field
        name="bio"
        render={({ field }) => (
          <TextArea error={errors.bio} rows={6} {...field} />
        )}
        required
      />
    </ActionFlowContainer>
  );
};

Form.propTypes = {
  errors: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  backTo: PropTypes.object,
  isSignup: PropTypes.bool,
  submitLabel: PropTypes.string,
};

export default Form;
