import React from "react";
import cx from "classnames";

import { analyticsEvent } from "utils/analytics";

import videoPoster from "images/video-poster.jpg";

import css from "./index.module.css";

const IntroVideo = () => {
  const videoClasses = cx("w-100 pointer br3", css.video);

  const videoUrl =
    "https://korukids-videos.s3.amazonaws.com/family-finder/homepage";

  const handleVideoPlay = () => {
    analyticsEvent("Landing Screen", "Play video");
  };

  return (
    <video
      className={videoClasses}
      poster={videoPoster}
      onPlay={handleVideoPlay}
      controls
    >
      <source src={`${videoUrl}.mp4`} type="video/mp4" />
      <source src={`${videoUrl}.webm`} type="video/webm" />
    </video>
  );
};

export default IntroVideo;
