import React from "react";
import PropTypes from "prop-types";
import { FieldArray } from "formik";
import uniqid from "uniqid";

import useScrollOnError from "hooks/scroll-on-error";

import ActionFlowContainer from "components/action-flow-container";
import FormErrors from "components/form-errors";

import Child from "./child";

const Form = ({
  values: { children },
  backTo,
  submitLabel,
  handleSubmit,
  errors,
  isSubmitting,
}) => {
  useScrollOnError(errors, isSubmitting);

  return (
    <ActionFlowContainer
      title="Tell us a bit about your children"
      backTo={backTo}
      action={{
        children: submitLabel,
        disabled: isSubmitting,
        type: "button",
        onClick: handleSubmit,
      }}
    >
      <FormErrors className="mb3" errors={errors} />
      <FieldArray name="children" validateOnChange={false}>
        {({ push, remove }) => (
          <>
            {children.map(({ tempId, hashid }, idx) => (
              <Child
                key={hashid || tempId}
                index={idx}
                errors={errors}
                allowRemove={!!tempId}
                showHeader={children.length > 1}
                onRemove={() => remove(idx)}
              />
            ))}
            <div className="tr mt2">
              <a
                href="#"
                className="rose b f6 no-underline"
                onClick={(e) => {
                  e.preventDefault();
                  push({ tempId: uniqid(), pickupNotes: "" });
                }}
              >
                + Add another child
              </a>
            </div>
          </>
        )}
      </FieldArray>
    </ActionFlowContainer>
  );
};

Form.propTypes = {
  errors: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  backTo: PropTypes.object,
  submitLabel: PropTypes.string,
};

export default Form;
