import React from "react";
import PropTypes from "prop-types";

const EditScreen = ({ component: Component, ...rest }) => {
  const handleSubmit = () => window.location.assign("/your-requirements");

  return (
    <div className="mw6 center h-100">
      <Component
        {...rest}
        onSubmit={handleSubmit}
        backTo={{ href: "/your-requirements" }}
        submitLabel="Update"
      />
    </div>
  );
};

EditScreen.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default EditScreen;
