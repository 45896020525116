import React from "react";
import PropTypes from "prop-types";

import ToggleButtonGroup from "components/toggle-button-group";

const RadioButtonGroup = ({
  field = {},
  name = field.name,
  value = field.value,
  onChange = field.onChange,
  ...rest
}) => {
  return (
    <ToggleButtonGroup
      isChecked={(key) => key === value}
      name={name}
      value={value}
      type="radio"
      onChange={onChange}
      {...rest}
    />
  );
};

RadioButtonGroup.propTypes = {
  field: PropTypes.object,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default RadioButtonGroup;
