import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import css from "./index.module.css";

const Pill = ({ className, children }) => {
  const rootClasses = cx("tc", className);
  const pillClasses = cx("f7 bg-white dark-gray br-pill dib b", css.pill);

  return (
    <div className={rootClasses}>
      <span className={pillClasses}>{children}</span>
    </div>
  );
};

Pill.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Pill;
