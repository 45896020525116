import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { analyticsEvent } from "utils/analytics";

import Button from "components/button";
import InfoBox from "components/info-box";

const SharePrompt = ({ totalFamilies, className }) => {
  const handleShareClick = () =>
    analyticsEvent("Local families", "Click share button", `${totalFamilies}`);

  const rootClasses = cx(className, "pa3");

  return (
    <InfoBox className={rootClasses}>
      <div className="b f4 mb2">Match with a family you know</div>
      <div className="lh-copy f6">
        Check if a family in your social circle is a match by posting a short
        message
      </div>
      <div className="tr mt3">
        <Button
          className="f6"
          component="a"
          onClick={handleShareClick}
          href="/share"
        >
          Find out more
        </Button>
      </div>
    </InfoBox>
  );
};

SharePrompt.propTypes = {
  totalFamilies: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default SharePrompt;
