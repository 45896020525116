import React, { useState } from "react";
import PropTypes from "prop-types";
import TextareaAutosize from "react-textarea-autosize";

import css from "./index.module.css";
import sendIcon from "./send.svg";

const MessageInput = ({ onSubmit }) => {
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSend = (e) => {
    e.preventDefault();

    if (message !== "") {
      onSubmit(message);
      setMessage("");
    }
  };

  return (
    <div className={`${css.root} bg-light-gray fixed bottom-0 w-100 mw6 tr`}>
      <div className="flex items-center">
        <TextareaAutosize
          maxRows={7}
          className={`b input-reset input br0 bn pa3 flex-grow-1 bg-light-gray outline-0 ${css.input}`}
          placeholder="Write your message..."
          onChange={handleChange}
          value={message}
          name="message"
        />
        <button className="pr2 outline-0 lh-0" onClick={handleSend}>
          <img src={sendIcon} alt="Send" />
        </button>
      </div>
    </div>
  );
};

MessageInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default MessageInput;
