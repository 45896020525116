import React from "react";

const { INTERCOM_APP_ID } = process.env;

const IntercomLink = () => (
  <a
    className="f6 no-underline purple"
    id="intercom-toggle"
    href={`mailto:${INTERCOM_APP_ID}@intercom-mail.com`}
  >
    Need help?
  </a>
);

export default IntercomLink;
