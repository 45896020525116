import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import Button from "components/button";
import IntercomLink from "components/intercom-link";

import Header from "./header";
import Navigation from "./navigation";

const ActionFlowScreen = ({
  backTo,
  children,
  className,
  headerClassName,
  navAction,
  title,
  description,
  action,
  ...rest
}) => {
  const rootClassName = cx(className, "flex flex-column h-100");

  return (
    <div className={rootClassName} {...rest}>
      <div className="flex-grow-1 ph3 pt2 purple">
        <div className="flex justify-between items-center pt2">
          {backTo && <Navigation {...backTo} />}
          {navAction ? (
            <a className="f6 no-underline link" {...navAction} />
          ) : (
            <IntercomLink />
          )}
        </div>
        {(title || description) && (
          <Header
            className={headerClassName}
            title={title}
            description={description}
          />
        )}
        {children}
      </div>
      {action && (
        <div className="pa3 pt4 tr">
          <Button className="f6" {...action} />
        </div>
      )}
    </div>
  );
};

ActionFlowScreen.propTypes = {
  action: PropTypes.object,
  backTo: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  description: PropTypes.node,
  headerClassName: PropTypes.string,
  navAction: PropTypes.object,
  title: PropTypes.string,
};

export default ActionFlowScreen;
