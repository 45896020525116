import { useEffect } from "react";
import { isEmpty } from "ramda";

import scrollToTop from "utils/scroll-to-top";

export default (errors, isSubmitting) => {
  useEffect(() => {
    if (!isEmpty(errors) && !isSubmitting) {
      scrollToTop();
    }
  }, [isSubmitting, errors]);
};
