import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Link } from "react-router-dom";

import arrowIcon from "images/icons/arrow.svg";

import css from "./index.module.css";

const Row = ({ icon, label, summary, to }) => {
  const rowClasses =
    "flex items-center w-100 no-underline pr2 pv3 pointer f4 bb b--light-gray db";
  const imageWrapperClasses = cx(css.imageWrapper, "flex-shrink-0 tc mr3");
  const summaryClasses = cx(css.summary, "nowrap purple b");
  const summaryWrapperClasses = cx(css.summaryWrapper, "flex-grow-1");

  return (
    <Link to={`/your-requirements/${to}`} className={rowClasses}>
      <div className={imageWrapperClasses}>
        <img src={icon} className={css.image} />
      </div>
      <div className={summaryWrapperClasses}>
        <div className="gray f6 mb1">{label}</div>
        <div className={summaryClasses}>{summary}</div>
      </div>
      <div className="flex-shrink-0 ml2">
        <img src={arrowIcon} className={css.arrow} />
      </div>
    </Link>
  );
};

Row.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default Row;
