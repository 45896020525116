import React, { Component } from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser";

import Button from "components/button";

class SentryBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { eventId: null };
    this.userId = document.getElementById("app").dataset.userId;
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);

      if (this.userId) {
        scope.setUser({ id: this.userId });
      }

      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  showReportDialog() {
    Sentry.showReportDialog({ eventId: this.state.eventId });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Button className="ma3 f6" onClick={this.showReportDialog.bind(this)}>
          Report feedback
        </Button>
      );
    }

    return this.props.children;
  }
}

SentryBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SentryBoundary;
