import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import css from "./index.module.css";

const styles = {
  rose: "white hover-white bg-rose hover-bg-rose--light active-bg-rose--dark b--transparent",
  toggled: "rose b--rose",
  outline: "purple b--moon-gray",
  text: "b--transparent",
};

const Button = ({
  children,
  className,
  component: Component = "button",
  style = "rose",
  ...rest
}) => {
  const rootClasses = cx(
    className,
    styles[style],
    css[style],
    "b link ph3 pv2 f4 dib bg-animate br2 tc sans-serif pointer ba relative"
  );

  return (
    <Component className={rootClasses} {...rest}>
      {children}
    </Component>
  );
};

Button.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.string,
};

export default Button;
