import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import css from "./index.module.css";

const Hamburger = ({ isActive, onClick }) => {
  const rootClasses = cx("dn-l", isActive && css.active, css.root);

  return (
    <button aria-label="Menu" className={rootClasses} onClick={onClick}>
      <div className={css.box}>
        <div className={css.inner}></div>
      </div>
    </button>
  );
};

Hamburger.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Hamburger;
