import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { analyticsEvent } from "utils/analytics";

import Button from "components/button";
import InfoBox from "components/info-box";

const NannyPrompt = ({ totalFamilies, className }) => {
  const handleNannyClick = () => {
    analyticsEvent("Local families", "Click nanny button", `${totalFamilies}`);
  };

  const rootClasses = cx(className, "pa3");

  return (
    <>
      <InfoBox className={rootClasses}>
        <div className="b f4 mb2">Looking for a nanny?</div>
        <div className="lh-copy f6">
          If you’re ready to start looking for a nanny (whether you’ve found a
          family or not) you can request a nanny here
        </div>
        <div className="tr mt3">
          <Button
            className="f6"
            component="a"
            onClick={handleNannyClick}
            href="https://www.korukids.co.uk"
            target="_blank"
          >
            Find me a nanny
          </Button>
        </div>
      </InfoBox>
    </>
  );
};

NannyPrompt.propTypes = {
  totalFamilies: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default NannyPrompt;
