import React, { useState } from "react";
import cx from "classnames";

import OnboardingStep from "./components/onboarding-step";
import css from "./index.module.css";

const Onboarding = () => {
  const [step, setStep] = useState(1);

  const title = [
    "In a nanny share, the nanny will look after yours & the other family’s children at the same time.",
    "First we’ll match you with a family in your neighbourhood.",
    "Then, we’ll match you with an amazing Koru Kids nanny, or you can use a nanny of your own.",
  ];

  const subTitle = [
    "Your child gets a playmate & you don’t have to dash to nursery",
    "You can decide whether you host the care in your home, the other family’s home, or you take it in turns",
    "And the whole matching service is free",
  ];

  const totalSteps = title.length;
  const isLastStep = step === totalSteps;

  const image = `image${step}`;
  const imageClasses = cx("min-h-100 pl3 pt4 pt5-ns", css.image, css[image]);

  const buttonText = isLastStep ? "Start" : "Next";

  const goToNext = () => {
    if (isLastStep) {
      window.location.assign("/signup/about-you");
    } else {
      setStep((currentStep) => currentStep + 1);
    }
  };

  return (
    <OnboardingStep
      title={title[step - 1]}
      imageClasses={imageClasses}
      currentStep={step}
      onClickNext={goToNext}
      buttonText={buttonText}
      subTitle={subTitle[step - 1]}
      totalSteps={totalSteps}
    />
  );
};

export default Onboarding;
