import React, { useState } from "react";
import PropTypes from "prop-types";
import copy from "clipboard-copy";

import { analyticsEvent } from "utils/analytics";

import BottomSheet from "components/bottom-sheet";
import Button from "components/button";

import chatIcon from "images/icons/chat.svg";

import css from "./index.module.css";

const ShareSheet = ({ onRequestClose, yourProfileUrl, isOpen = false }) => {
  const [hasCopied, setCopied] = useState(false);

  const handleCopyClick = async (e) => {
    e.preventDefault();

    analyticsEvent("Signup", "Copy profile URL", "Copy");

    await copy(yourProfileUrl);
    setCopied(true);
  };

  const handleUrlClick = (e) => {
    e.target.select();
  };

  const resultsPath = "/local-families?signup=true";

  return (
    <BottomSheet isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className="w-100 mw7 center mb6-l bg-not-white pa3 pt4 cf">
        <div className="fl w-10 tc pt1 tr">
          <img src={chatIcon} />
        </div>
        <div className="fl w-90 pl2 dark-gray">
          <h3 className="pv1 lh-title f5">
            Share your childcare with a family in your wider circle
          </h3>
          <div className="f6 pv1 measure lh-copy break-word mb3">
            Lots of families end up sharing with a friend of a friend. Here’s
            how to find yours! Share the link to your profile (below) in a
            WhatsApp or Facebook group. We’ll check all the logistics to see if
            they’re a good match for you (and also see if they match any of our
            other families)
          </div>
          <div className="flex f6 measure mb3">
            <input
              type="text"
              className="truncate ph2 flex-grow-1 br2 br--left bg-white ba br-0 b--black-20 f6"
              onClick={handleUrlClick}
              value={yourProfileUrl}
              readOnly
            />
            <Button
              className="br--right f6"
              component="a"
              href="#"
              onClick={handleCopyClick}
            >
              Copy
            </Button>
          </div>
          {!hasCopied && (
            <Button
              className={`${css.skip} f6`}
              style="text"
              component="a"
              href={resultsPath}
            >
              Skip
            </Button>
          )}
          {hasCopied && (
            <div className="tr">
              <Button className="f6" component="a" href={resultsPath}>
                View matching families
              </Button>
            </div>
          )}
        </div>
      </div>
    </BottomSheet>
  );
};

ShareSheet.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  yourProfileUrl: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
};

export default ShareSheet;
