import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import css from "./index.module.css";

const SlideshowCounter = ({ currentStep, totalSteps }) => {
  const rootClasses = cx(
    "flex justify-center justify-start-ns items-center-ns w-100",
    css.root
  );
  const dotClasses = cx("ba bw1 b--white", css.dot);
  const activeDotClasses = cx(dotClasses, css.current);

  return (
    <div className={rootClasses}>
      {Array.from(new Array(totalSteps), (_, step) => (
        <div
          key={step}
          className={step + 1 === currentStep ? activeDotClasses : dotClasses}
        />
      ))}
    </div>
  );
};

SlideshowCounter.propTypes = {
  currentStep: PropTypes.number,
  totalSteps: PropTypes.number,
};

export default SlideshowCounter;
