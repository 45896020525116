import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "hooks/use-unique-id";

const TextArea = ({
  className,
  error,
  label,
  hint,
  id,
  field = {},
  name = field.name,
  value = field.value,
  onChange = field.onChange,
  ...rest
}) => {
  const inputId = useUniqueId(id);

  const { form: _, ...inputRest } = rest;

  return (
    <div className={className}>
      {label && (
        <label className="pv1 mb1 purple f6 dib lh-title" htmlFor={inputId}>
          {label}
        </label>
      )}
      {hint && <div className="medium-gray f6 mb1 pb1 lh-title">{hint}</div>}
      <textarea
        id={inputId}
        name={name}
        value={value}
        className="lh-copy input-reset input bg-white ba b--black-20 w-100 mw-100 pa2 f7 br2"
        onChange={onChange}
        rows={4}
        {...inputRest}
      />
    </div>
  );
};

TextArea.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  field: PropTypes.object,
  hint: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default TextArea;
