import React, { useState } from "react";
import PropTypes from "prop-types";

import questionMarkIcon from "images/icons/question-mark.svg";
import daysKeyIcon from "images/icons/days-key.svg";

import ActionFlowContainer from "components/action-flow-container";
import FamilyProperties from "components/family-properties";
import InfoSheet from "components/info-sheet";
import OkieDoke from "components/okie-doke";
import DaysIndicator from "./components/days-indicator";
import ShareSheet from "./components/share-sheet";
import StaticMap from "./components/static-map";

const ProfileScreen = ({
  hashid,
  bio,
  familyName,
  theirEssentialDays,
  theirPreferableDays,
  yourEssentialDays,
  yourPreferableDays,
  minDaysCare,
  maxDaysCare,
  daysNote,
  numDaysCareNote,
  pickupNotes,
  yourProfileUrl,
  lastActive,
  isSelf = false,
  isSignup = false,
  ...rest
}) => {
  const [daysExplanationOpen, setDaysExplanationOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);

  const handleQuestionClick = () => {
    setDaysExplanationOpen(true);
  };

  const handleExplanationRequestClose = () => {
    setDaysExplanationOpen(false);
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    setShareOpen(true);
  };

  const handleShareRequestClose = () => {
    setShareOpen(false);
  };

  let primaryAction = null;
  let navAction = null;

  if (isSignup) {
    primaryAction = {
      children: "Next",
      href: "#",
      onClick: handleNextClick,
      component: "a",
    };
  } else if (!isSelf) {
    primaryAction = {
      children: "Send them a message",
      href: `/conversations/${hashid}`,
      component: "a",
    };
  }

  if (isSelf && !isSignup) {
    navAction = { href: "/your-requirements", children: "Edit your profile" };
  }

  return (
    <>
      <div className="mw6 center h-100">
        <ActionFlowContainer
          title={familyName}
          headerClassName="mb2"
          backTo={{ href: "/local-families", push: false }}
          action={primaryAction}
          navAction={navAction}
        >
          <div className="f7 silver">Last active {lastActive}</div>
          <FamilyProperties {...rest} />
          <div className="mt3 mb2">
            <StaticMap {...rest} />
          </div>
          <p className="lh-copy medium-gray">{bio}</p>
          <div className="flex items-center">
            <div className="mv3 b f3 flex-grow-1">Days</div>
            <img
              className="h-100 pointer"
              src={questionMarkIcon}
              onClick={handleQuestionClick}
            />
          </div>
          <div className="flex">
            <div className="w-50">
              <DaysIndicator
                theirEssentialDays={theirEssentialDays}
                theirPreferableDays={theirPreferableDays}
                yourEssentialDays={yourEssentialDays}
                yourPreferableDays={yourPreferableDays}
              />
            </div>
            <div className="silver f7 w-50 lh-copy">{daysNote}</div>
          </div>
          <hr className="b--solid bb-0 b--moon-gray mv3" />
          <div className="flex">
            <div className="w-50">
              <div className="f7 medium-gray lh-title">
                Minimum days <strong className="pl1">{minDaysCare}</strong>
              </div>
              <div className="f7 medium-gray lh-title">
                Maximum days <strong className="pl1">{maxDaysCare}</strong>
              </div>
            </div>
            <div className="silver f7 w-50 lh-copy">{numDaysCareNote}</div>
          </div>
          {pickupNotes.length ? (
            <>
              <div className="mt3 mb2 b f3">Pick-up notes</div>
              {pickupNotes.map(({ child, text }, idx) => (
                <div key={idx} className="f7 lh-copy mb2 medium-gray">
                  {pickupNotes.length > 1 && <span>{child} – </span>}
                  {text ? (
                    <strong>“{text}”</strong>
                  ) : (
                    <span>no pick-up required</span>
                  )}
                </div>
              ))}
            </>
          ) : (
            <div className="f7 mt3 medium-gray">No pick-up required</div>
          )}
        </ActionFlowContainer>
      </div>
      <InfoSheet
        iconSrc={daysKeyIcon}
        isOpen={daysExplanationOpen}
        onRequestClose={handleExplanationRequestClose}
        title="Which days do you match with?"
        message={
          <>
            <p>A solid green box means the day was selected as “Essential”.</p>
            <p className="mt2">
              A grey box with a green outline means the day was selected as
              being “Flexible”.
            </p>
            <p className="mt2">
              A grey box with no outline means the day was not selected.
            </p>
          </>
        }
      />
      {isSignup && (
        <>
          <OkieDoke
            title="Here’s your profile"
            message="Now go forth and find families by browsing through our database, sharing your profile or both."
          />
          <ShareSheet
            isOpen={shareOpen}
            yourProfileUrl={yourProfileUrl}
            onRequestClose={handleShareRequestClose}
          />
        </>
      )}
    </>
  );
};

ProfileScreen.propTypes = {
  bio: PropTypes.string.isRequired,
  familyName: PropTypes.string.isRequired,
  hashid: PropTypes.string.isRequired,
  lastActive: PropTypes.string.isRequired,
  latitude: PropTypes.number.isRequired,
  location: PropTypes.string.isRequired,
  longitude: PropTypes.number.isRequired,
  maxDaysCare: PropTypes.number.isRequired,
  minDaysCare: PropTypes.number.isRequired,
  pickupNotes: PropTypes.array.isRequired,
  yourEssentialDays: PropTypes.array.isRequired,
  yourPreferableDays: PropTypes.array.isRequired,
  daysNote: PropTypes.string,
  isSelf: PropTypes.bool,
  isSignup: PropTypes.bool,
  numDaysCareNote: PropTypes.string,
  theirEssentialDays: PropTypes.array,
  theirPreferableDays: PropTypes.array,
  yourProfileUrl: PropTypes.string,
};

export default ProfileScreen;
