import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";

import useScrollOnError from "hooks/scroll-on-error";

import ActionFlowContainer from "components/action-flow-container";
import FormErrors from "components/form-errors";
import Input from "components/input";

const Form = ({ handleSubmit, errors, isSubmitting }) => {
  useScrollOnError(errors, isSubmitting);

  return (
    <ActionFlowContainer
      title="Tell us a bit about your family"
      description="This will tell us if there are families looking for childcare near you"
      action={{
        children: "Next",
        disabled: isSubmitting,
        type: "button",
        onClick: handleSubmit,
      }}
    >
      <FormErrors errors={errors} />
      <form onSubmit={handleSubmit}>
        <div>
          <Field
            type="text"
            name="firstName"
            label="First name"
            error={errors.firstName}
            component={Input}
            required
          />
        </div>
        <div>
          <Field
            type="text"
            name="lastName"
            label="Last name"
            error={errors.lastName}
            component={Input}
            required
          />
        </div>
        <div>
          <Field
            type="email"
            name="email"
            label="Email"
            error={errors.email}
            component={Input}
            required
          />
        </div>
        <div>
          <Field
            type="text"
            name="postcode"
            label="Postcode"
            error={errors.postcode}
            component={Input}
            required
          />
        </div>
      </form>
      <div className="pt2 lh-copy mt2 f7 light-silver">
        By clicking “Next”, you agree to our{" "}
        <a href="https://korukids.co.uk/after-school/signup-terms-and-conditions">
          Terms
        </a>
        . Learn how we collect, use and share your data in our{" "}
        <a href="https://korukids.co.uk/privacy-policy">Privacy Policy</a> and
        how we use cookies and similar technology in our{" "}
        <a href="https://korukids.co.uk/cookie-policy">Cookie Policy</a>.
      </div>
    </ActionFlowContainer>
  );
};

Form.propTypes = {
  errors: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default Form;
