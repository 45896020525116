import { useState, useEffect } from "react";
import uniqid from "uniqid";

const useUniqueId = (id) => {
  const [value, setValue] = useState(id || uniqid());

  useEffect(() => {
    setValue(id || uniqid());
  }, [id]);

  return value;
};

export default useUniqueId;
