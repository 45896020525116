import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { includes } from "ramda";

import css from "./index.module.css";

const DAYS = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const DaysIndicator = ({
  theirEssentialDays,
  theirPreferableDays,
  yourEssentialDays,
  yourPreferableDays,
}) => {
  const dayNameClasses = cx(css.cell, "tc dib mr1 f7 medium-gray");
  const rowHeaderClasses = cx(css.rowHeader, "dib pr1 f7 medium-gray v-mid");

  const baseDayClasses = "dib mr1 br1 ba bg-light-gray b--transparent v-mid";
  const dayClasses = (day, essentialDays, preferableDays) =>
    cx(
      {
        "bg-green": includes(day, essentialDays),
        "b--green": includes(day, preferableDays),
      },
      baseDayClasses,
      css.cell
    );

  return (
    <div className={css.root}>
      <div className="mb1">
        <div className={dayNameClasses}>M</div>
        <div className={dayNameClasses}>T</div>
        <div className={dayNameClasses}>W</div>
        <div className={dayNameClasses}>T</div>
        <div className={dayNameClasses}>F</div>
        <div className={dayNameClasses}>S</div>
        <div className={dayNameClasses}>S</div>
      </div>
      {theirEssentialDays && (
        <div className="mb1 pt1">
          {DAYS.map((day) => (
            <div
              key={day}
              className={dayClasses(
                day,
                theirEssentialDays,
                theirPreferableDays
              )}
            />
          ))}
          <div className={rowHeaderClasses}>Them</div>
        </div>
      )}
      <div>
        {DAYS.map((day) => (
          <div
            key={day}
            className={dayClasses(day, yourEssentialDays, yourPreferableDays)}
          />
        ))}
        <div className={rowHeaderClasses}>You</div>
      </div>
    </div>
  );
};

DaysIndicator.propTypes = {
  yourEssentialDays: PropTypes.array.isRequired,
  yourPreferableDays: PropTypes.array.isRequired,
  theirEssentialDays: PropTypes.array,
  theirPreferableDays: PropTypes.array,
};

export default DaysIndicator;
