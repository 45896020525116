import React from "react";
import PropTypes from "prop-types";

import FamilyList from "./family-list";

const FlexResults = ({ families, title, type }) => (
  <>
    <div className="f7 bg-not-white pa2 medium-gray lh-copy mb3-ns mh3-ns">
      {title}
    </div>
    <FamilyList
      families={families}
      highlight={type}
      profileLinkReferral={
        type === "startDate" ? "flex-start-date" : "flex-distance"
      }
    />
  </>
);

FlexResults.propTypes = {
  families: PropTypes.array.isRequired,
  title: PropTypes.node.isRequired,
  type: PropTypes.oneOf(["startDate", "distance"]).isRequired,
};

export default FlexResults;
