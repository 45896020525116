import React from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import cx from "classnames";

import css from "./index.module.css";

const BottomSheet = ({ isOpen, children, onRequestClose, ...rest }) => (
  <ReactModal
    {...rest}
    className={{
      base: css.contentBase,
      afterOpen: css.contentAfterOpen,
      beforeClose: css.contentBeforeClose,
    }}
    overlayClassName={{
      base: cx(css.overlayBase, "z-3"),
      afterOpen: css.overlayAfterOpen,
      beforeClose: css.overlayBeforeClose,
    }}
    isOpen={isOpen}
    closeTimeoutMS={450}
    onRequestClose={onRequestClose}
  >
    {children}
  </ReactModal>
);

BottomSheet.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default BottomSheet;
