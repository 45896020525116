import React, { useEffect } from "react";
import cx from "classnames";
import { Link } from "react-router-dom";

import { fbEvent } from "utils/analytics";
import { isLoggedIn } from "utils/rails";

import Button from "components/button";

import css from "./index.module.css";

const CovidScreen = () => {
  const imageClasses = cx("pl3 pt4 pt5-ns z-1", css.image);
  const solidClasses = cx("pl3 flex-grow-1 pv3", css.solid);

  useEffect(() => {
    fbEvent("ViewContent");
  }, []);

  return (
    <div className="min-h-100 flex flex-column">
      <div className={imageClasses}>
        <div className="mw8 center pr3">
          <div className="w-80 w-60-m w-40-l">
            <h1 className="f1-ns f2 white mb2-ns">
              Find a local family to share your childcare with
            </h1>
            <h2 className="f4 f2-ns white normal mt1">
              Share the costs and get a playmate for your child
            </h2>
          </div>
        </div>
        <div className={css.angledBorder} />
      </div>
      <div className={solidClasses}>
        <div className="mw8 center pr3">
          <div className="w-60-m w-40-l">
            <div className="pb3 tr">
              {!isLoggedIn() && (
                <>
                  <a href="/login" className="f6 white mt3">
                    Existing user? Login
                  </a>
                </>
              )}
            </div>
            <h2 className="f3 f2-ns white mb3">
              I need to share my childcare during the coronavirus outbreak
            </h2>
            <div className="white lh-title">
              <p className="mb3">
                Coronavirus may force schools and nurseries to close, leaving
                many families without childcare. If you’re looking to join
                forces with a local family to share the childcare load during
                the coronavirus outbreak, then sign up below. Find a great local
                family to share a nanny with for your baby or toddler.
              </p>
              <Button
                component={Link}
                className="f6 mb4"
                to="/signup/about-you?source=covid"
              >
                I need coronavirus cover
              </Button>
            </div>
            <h2 className="f3 f2-ns white mb3">
              I’m looking for a long term nanny share
            </h2>
            <div className="white lh-title">
              <p className="mb3">
                If you’re looking for a longer term nanny share for your family,
                sign up below.
              </p>
              <Button component={Link} className="f6" to="/">
                I want a long-term nanny share
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CovidScreen;
