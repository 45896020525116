import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { path } from "ramda";
import cx from "classnames";

import TextArea from "components/text-area";
import RadioButtonGroup from "components/radio-button-group";

import DateOfBirth from "./date-of-birth";

const Child = ({ index, errors, allowRemove, showHeader, onRemove }) => {
  const headerClasses = cx("f4 b mb2", index && "mt5");

  return (
    <>
      {showHeader && (
        <div className={headerClasses}>
          Child {index + 1}
          {allowRemove && (
            <a
              href="#"
              className="rose b f6 no-underline ml2"
              onClick={(e) => {
                e.preventDefault();
                onRemove();
              }}
            >
              Remove
            </a>
          )}
        </div>
      )}
      <Field
        name={`children[${index}]gender`}
        label="Gender"
        error={path(["children", index, "gender"], errors)}
        component={RadioButtonGroup}
        labelClassName="w-third"
        options={[
          ["Boy", "boy"],
          ["Girl", "girl"],
          ["Not sure", "unknown"],
        ]}
        required
      />
      <DateOfBirth
        namePrefix={`children[${index}]`}
        error={path(["children", index, "dob"], errors)}
      />
      <Field
        name={`children[${index}]pickupNotes`}
        className="mt4"
        component={TextArea}
        label="Optional pick-up notes"
        hint="If this child requires a pick-up during the day (say from nursery, school or grandparents) please enter relevant info here about timing and/or location."
        rows={2}
      />
    </>
  );
};

Child.propTypes = {
  allowRemove: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  showHeader: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired,
  errors: PropTypes.object,
  hashid: PropTypes.string,
  tempId: PropTypes.string,
};

export default Child;
